// Variables

// *** Fonts ***
$sans-serif: 'Roboto', Arial, sans-serif;

// Colors
$primary: #e60000;
$secondary: #337ab7;
$white: #fff;
$black: #000;

// Brights
$alert: #dc3545;
$warning: #ffc107;
$success: #28a745;
$focus: #6dcff6;
$social: #0077b5;

$info: lighten($secondary, 10);

// Greyscale
$grey-1: #f8f9fa;
$grey-2: #e9ecef;
$grey-3: #dee2e6;
$grey-4: #ced4da;
$grey-5: #adb5bd;
$grey-6: #6c757d;
$grey-7: #495057;
$grey-8: #343a40;
$grey-9: #212529;

// *** Transitions ***

$transition-1: color 0.1s linear, background-color 0.1s linear;

// *** Borders and Shadows ***

$thin-border: 1px solid $grey-3;
$text-shadow: 1px 1px 1px $grey-9;
$text-shadow-2: 0 0 2px rgba(0, 0, 0, 0.7);
$box-shadow: 1px 2px 4px $grey-6;

// Spacing

$component-spacing: 20px 0;

// Layout
$grid-sm: 576px;
$grid-md: 768px;
$grid-lg: 1025px;
$grid-xl: 1440px;
