// Styling for Default HTML Elements

// ** GLOBAL CSS RESET FOR ALL BROWSERS **
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
}

:focus {
  outline: 0;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
}

blockquote,
q {
  quotes: '' '';
}

// Custom for AXN

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 16px !important; /* apply 16px at html so REM works */
}

body {
  font-family: $sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0;
}

footer {
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
  color: $primary;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
  margin: 0 0 0.75rem 0;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

a {
  color: $primary;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  transition: $transition-1;
}

p {
  padding: 10px 0;
}

input {
  border: 1px solid $grey-5;
}

main {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
  min-height: calc(65vh);
}

main > section {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
fieldset {
  /* Apply Default Fieldset Styling for now. */
  border-width: 2px;
  border-style: groove;
  border-color: threedface;
  border-image: initial;
  border-radius: 10px;
}

legend {
  width: auto;
  font-size: 1.15rem;
  font-weight: bold;
  padding: .5rem !important;
  color: $primary;
}

.form-check {
  margin-bottom: 1rem;
}
