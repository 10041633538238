.axnToolTip {
  border: 1px solid rgba(0,0,0,0.25);
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  background: #fff;
  left: 0;
  right: 0;
  padding: 10px 20px;
  position: absolute;
  top: 24px;
  z-index: 1000;
}

.tooltipToggle {
  position: absolute;
  right: 0;
}
