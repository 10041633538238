.axn-form {
  display: flex;
  flex-direction: column;

  textarea {
    width: 100%;
  }

  button[type='reset'] {
    margin-left: 20px;
  }
}

button:focus {
  outline: none;
}

.axn-form-wrapper {
  background: $grey-2;
  flex: 0 1 100%;
  padding: 20px;

  &-sm,
  &-md {
    @extend .axn-form-wrapper;
  }

  @media (min-width: $grid-sm) {
    &-sm {
      max-width: 30%;
    }
    &-md {
      max-width: 65%;
    }
  }
}

#requestAccessForm2 {
  .row .form-group {
    display: flex;
    flex: 1;
    flex-direction: column;

    &:last-child {
      margin-left: 10px;
    }

    &:first-child {
      margin-right: 10px;
    }
  }
}

#addContactForm {
  .row {
    flex-wrap: wrap;
    justify-content: space-between;

    .form-group {
      display: flex;
      flex: 0 1 auto;
      flex-direction: column;
      width: 48%;
    }
  }
}

#addToPrint {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  input {
    display: flex;
    margin-right: 20px;
    width: auto;
  }
}
