@import '../../../scss/foundation/variables';
@import '../../../scss/foundation/placeholders';

#admin-nav {
  background-color: $theme-red;
}

#admin-nav {
  @extend %base-width;

  a {
    color: $white;
    padding: 0 5px;
  }
  .dropdown-item a {
    color: $theme-red;
  }

  a:hover {
    color: $black;
  }

  li {
    &:last-child a {
      border: 0;
    }
  }

  ul.nav > li.nav-item {
    width: 100%;
    transition: 1s ease all;
    box-sizing: border-box;
    margin: 0 5px;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
    text-align: center;
    padding: 0px;
    a {
      padding: 5px;
      transition: 0.5s ease all;
      &.dropdown-toggle:hover {
        color: ghostwhite;
      }
    }

    .dropdown-toggle::after {
      margin-left: 0.4em;
      vertical-align: 0.15em;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out all;
    }

    @media (max-width: 1280px) {
      &.dropdown.show {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        .dropdown-toggle::after {
          transform: rotate(180deg);
          transition: 0.5s ease-in-out all;
        }
      }
    }
    @media (min-width: 1280px) {
      position: relative;
      width: auto;
      transition: 1s ease all;
      &:last-child:after {
        display: none;
      }
    }
  }

  .navbar-nav .dropdown-menu {
    @media (max-width: 1280px) {
      position: relative !important;
      transform: none !important;
      text-align: center;
    }
    padding: 0;
    margin: 0 10px 10px;
  }
}

.nav-item-text {
  padding: 0 0 0 15px;
}
