@import '../../scss/variables';

.btn-group {
  width: 100%;
}

.search-list-tools {
  @media (max-width: map-get($grid-breakpoints, lg) + 30px) {
    flex-direction: column;
    .wizard-pagesize-select-wrapper {
      margin: 0 auto 1rem auto;
      text-align: center;
    }
    .pagination {
      align-self: center;
    }
  }
}
