.dominantAirlineWrapper{
  margin: 3rem 0 1rem 0;
}

.dominantAirlinesFields {
  background: rgba(255,255,255, .75);
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.5);
  h4{
    text-align: left;
    margin: .5rem 0 1rem 0;
  }
  .existing-dominant-airlines {
    border: 1px solid rgba(0,0,0,0.25);
    border-radius: 5px;
    .dominant-airline-col-names{
      background: rgba(0,0,0,0.55);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: #fff;
      padding: 5px;
    }
    .row:not(.dominant-airline-col-names){
      padding: 5px;
      /* stripe style the rows */
      &:nth-child(odd){
        background: rgba(0,0,0,0.15);
      }
    }
  }
}
