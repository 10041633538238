#wizard-stepper{
  .btn-primary.disabled,
  .btn-primary:disabled {
    display: none;
  }

  .step-header {
    margin: 0.5rem 0 1.5rem 0;
    text-align: center;
  }

  .stepperButton {
    @media (max-width: 950px) {
      button.btn.btn-primary {
        font-size: 0.5em;
      }
    }
    @media (min-width: 768px) {
      button.btn.btn-primary {
        font-size: 0.75em;
      }
    }
    @media (min-width: 992px) {
      button.btn.btn-primary {
        font-size: 0.9em;
      }
    }
  }
}
