// Placeholders

%base-width {
  margin: 0 auto;
  width: 100%;
}

%mp0 {
  margin: 0;
  padding: 0;
}

%list-style-removal {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
