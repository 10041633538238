// Overrides for Bootstrap Styling
$theme-colors: (
  'primary': #e60000,
  'secondary': #337ab7,
  'alert': #dc3545,
  'warning': #ffc107,
  'success': #28a745,
  'info': lighten($secondary, 10)
);

.card {
  border: 0;
}

.card-title {
  color: $primary;
  font-size: 1.5rem;
}

.bg-axn{
  background-color: $primary !important;
  color: #FFF;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  color: $white;
  a {
    color: $white;
  }
  &:hover {
    background-color: $grey-8;
    border-color: $grey-8;
  }
}



.form-control {
  border-color: $grey-5;
}

.page-item.active .page-link {
  background-color: $primary;
  border-color: $primary;
}

.page-link {
  color: $primary;
}

$container-max-widths: (
  xl: 1890px
) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 800px,
  lg: 1140px,
  xl: 1280px
);

a:hover {
  color: $black;
}
