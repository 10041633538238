.welcome-information {
  .card {
    max-width: 30%;
    flex: 1;
  }

  .card-body {
    flex: 0 0 auto;
  }
}
