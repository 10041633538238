#adspeed-leaderboard {
    width: 748px;
    height: 90px;
    max-height: 12vw;
    margin: 0 auto 30px;
    padding: 0 10px;
    max-width: 100%;

    iframe {
        width: 100%;
        height: 100%;    
    }
}