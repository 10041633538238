// Placeholders

%base-width {
  margin: 0 auto;
  max-width: 90%;
  width: 100%;

  @media (min-width: 1600px) {
    max-width: 1366px;
  }
}

%mp0 {
  margin: 0;
  padding: 0;
}

%list-style-removal {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
