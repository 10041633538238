$theme-red: #e60000;
$theme-grey: #adb5bd;
$theme-white: #fff;
$theme-black: #212529;

$container-max-widths: (
  xl: 1336px
) !default;

$grid-breakpoints: (
  // Extra small screen / phone
    xs: 0,
  // Small screen / phone
    sm: 576px,
  // Medium screen / tablet
    md: 768px,
  // Large screen / desktop
    lg: 992px,
  // Extra large screen / wide desktop
    xl: 1340px
);

// Colors
$primary: #e60000;
$secondary: #afb7ba;
$white: #fff;
$black: #000;