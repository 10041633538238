@import '../../scss/base/all';

$bg-color: rgb(175, 183, 186);

footer {
  background-color: $bg-color;
  position: relative;
  &::before {
    background: rgba(255, 255, 255, 1) url('footer-background-image.png')
      no-repeat center/cover;
    background-position-x: right;
    content: '';
    display: block;
    height: 150px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

#footer {
  margin-top: 150px;
  box-sizing: border-box;
}

#footer-sitemap {
  .nav-list {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-column-gap: 10px;
    justify-content: center;
    justify-items: center;
  }
}
