.DayPickerKeyboardShortcuts_show__bottomRight::before{
  border-right: 33px solid $primary;
}

.DateInput_input__focused{
  border-bottom: 2px solid $primary;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
  background: $primary;
  border: 1px double $primary;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before{
  border-right: 33px solid $secondary;
}