.listManagerWrapper {
  margin: 3rem 0 1rem 0;
}

.masterlist-controls {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .masterlist-controls {
    flex-direction: column;
  }
  .print-masterlist {
    display: none !important;
  }
}

.listManagerFields {
  background: rgba(255, 255, 255, 0.75);
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  h4 {
    text-align: left;
    margin: 0.5rem 0 1rem 0;
  }
  .existing-list-items {
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    .list-item-col-names {
      background: rgba(0, 0, 0, 0.55);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: #fff;
      padding: 5px;
    }
    .row:not(.list-item-col-names) {
      padding: 5px;
      /* stripe style the rows */
      &:nth-child(odd) {
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }
}


::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  margin-bottom: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

