.admin-panel-grid {
  display: flex;
  flex-wrap: wrap;
}

.home-panel-card {
  display: flex;
  padding: 0.5em;
  width: 100%;
  @media all and (min-width: 50em) {
    width: 50%;
  }
}

.home-card-link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1em;
  width: 100%;
  .card {
    flex: 1 0 auto;
  }
}

.home-card-link:hover {
  text-decoration: none;
  .card-title {
    color: #9a0000;
  }
}
