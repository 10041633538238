// Styling for the WelcomePage component

#welcomeInformation {
  justify-content: space-between;
}

.welcome-message {
  flex: 1;
  max-width: 65%;
}
