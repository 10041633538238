.tab-pane {
  background: #fff;
  padding: 20px 40px 40px 40px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-color: #e9ecef #e9ecef #dee2e6; // match bootstrap styling for consistency
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
ul.nav-tabs {
  a.nav-link {
    color: $primary;
    &:focus,
    &:hover {
      background-color: #fbfbfb;
      border-bottom: none;
      cursor: pointer;
    }
    &.active {
      color: $black;
    }
  }
}
