// Classes common to multiple elements

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.col {
  flex: 1;
  flex-direction: column;
  flex-basis: 320px;
}

.col-medium-ad {
  flex: 0;
  flex-direction: column;
  align-content: center;
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.base-container {
  @extend %base-width;
  padding: 40px 0;
}

.axn-title {
  @extend .text-center;
  margin-bottom: 20px;
}

.axn-component {
  display: flex;
  flex: 0 1 100%;
  flex-direction: column;
  margin: $component-spacing;
}

.axn-primary {
  color: $primary;
}

.axn-secondary {
  color: $secondary;
}

.nav-list {
  @extend %mp0;
  list-style: none;
}

.danger {
  color: $alert;
}

.underline {
  border-bottom: 5px solid $alert;
  padding-bottom: 10px;
  text-align: center;
}

.wizard-title {
  margin: .5em 0 .5em 0;
}

.wizard-instruction{
  margin: 1.5em 0 1.25em 0;
}